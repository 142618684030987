// src/components/DoctorMaleIllustration.tsx

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { styled } from '@mui/system';

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

interface DoctorIllustrationProps {
  width?: number | string;
  height?: number | string;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
  onClick?: () => void;
  animated?: boolean;
}

const DoctorFemaleIllustration: React.FC<DoctorIllustrationProps> = ({
  width = '100%',
  height = 'auto',
  className,
  style,
  alt = 'Doctor Male Illustration',
  onClick,
  animated = true,
}) => {
  const theme = useTheme();

  const animationStyles = animated
    ? {
        '@keyframes float': {
          '0%': { transform: 'translateY(0px)' },
          '50%': { transform: 'translateY(-10px)' },
          '100%': { transform: 'translateY(0px)' },
        },
        animation: 'float 3s ease-in-out infinite',
      }
    : {};

  return (
    <IllustrationWrapper
      className={className}
      onClick={onClick}
      sx={{
        width,
        height,
        cursor: onClick ? 'pointer' : 'default',
        minHeight: '500px',
        ...animationStyles,
        ...style,
      }}
      role={onClick ? 'button' : 'img'}
      aria-label={alt}
    >
      <Box
        component="img"
        src="/images/homepage/doctor-female.svg" // Ensure the path is correct
        alt={alt}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          display: 'block',
        }}
      />
    </IllustrationWrapper>
  );
};

export default DoctorFemaleIllustration;