// components/HeroIllustration.tsx

import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTheme, alpha, Theme } from '@mui/material/styles';
import {
  motion,
  useInView,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import { styled } from '@mui/system';

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  minHeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  perspective: '1000px',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    minHeight: 400,
  },
}));

const Shape = styled(motion.div)<{ color: string }>(({ color }) => ({
  position: 'absolute',
  background: color,
  borderRadius: '50%',
  filter: 'blur(20px)',
  cursor: 'pointer',
}));

const GridPattern = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  width: '200%',
  height: '200%',
  background: `linear-gradient(${alpha(
    theme.palette.divider,
    0.1
  )} 1px, transparent 1px),
                 linear-gradient(90deg, ${alpha(
                   theme.palette.divider,
                   0.1
                 )} 1px, transparent 1px)`,
  backgroundSize: '50px 50px',
  transform: 'translate(-50%, -50%)',
}));

const InteractiveCard = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  padding: theme.spacing(2),
  boxShadow: (theme.shadows as Array<string>)[4], // Cast to Array<string>
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  cursor: 'pointer',
  width: 200,
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Particle = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  width: 6,
  height: 6,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
}));

interface ParticleProps {
  x: number;
  y: number;
  color: string;
}

const HeroIllustration: React.FC = () => {
  const theme = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(wrapperRef, { once: false, margin: '-100px' });
  const controls = useAnimation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [particles, setParticles] = useState<ParticleProps[]>([]);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const rotateX = useTransform(mouseY, [-300, 300], [15, -15]);
  const rotateY = useTransform(mouseX, [-300, 300], [-15, 15]);

  const colors = useMemo(
    () => [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.info.main,
    ],
    [theme]
  );

  const shapes = useMemo(
    () => [
      {
        size: 220,
        x: -120,
        y: -80,
        color: theme.palette.primary.main,
        delay: 0,
      },
      {
        size: 180,
        x: 140,
        y: 60,
        color: theme.palette.secondary.main,
        delay: 0.2,
      },
      {
        size: 120,
        x: -30,
        y: 140,
        color: theme.palette.info.main,
        delay: 0.4,
      },
    ],
    [theme]
  );

  const cards = useMemo(
    () => [
      {
        title: 'Secure Data',
        x: -120,
        y: -80,
        rotation: -10,
        description: 'Blockchain-powered security for patient data.',
      },
      {
        title: 'Efficient Workflow',
        x: 120,
        y: 80,
        rotation: 10,
        description: 'Streamlined processes with our integrated systems.',
      },
    ],
    []
  );

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [isInView, controls]);

  // Throttled mouse move handler using requestAnimationFrame
  useEffect(() => {
    let animationFrameId: number;

    const handleMouseMove = (e: MouseEvent) => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;

        animationFrameId = window.requestAnimationFrame(() => {
          setMousePosition({ x, y });
          mouseX.set(x);
          mouseY.set(y);
        });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [mouseX, mouseY]);

  // Generate particles
  useEffect(() => {
    const particleCount = window.innerWidth > 768 ? 50 : 25; // Adjust based on screen size
    const newParticles = Array.from({ length: particleCount }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      color: colors[Math.floor(Math.random() * colors.length)],
    }));
    setParticles(newParticles);
  }, [colors]);

  return (
    <IllustrationWrapper ref={wrapperRef}>
      <motion.div
        style={{
          width: '100%',
          height: '100%',
          rotateX,
          rotateY,
          transformStyle: 'preserve-3d',
        }}
      >
        <GridPattern
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.1 }}
          transition={{ duration: 1 }}
        />

        {/* Particles */}
        {particles.map((particle, index) => (
          <Particle
            key={index}
            style={{ backgroundColor: particle.color }}
            initial={{ x: particle.x, y: particle.y, opacity: 0 }}
            animate={{
              y: [particle.y, particle.y - 200],
              opacity: [0, 1, 0],
              scale: [1, 1.5, 0],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              delay: index * 0.1,
              ease: 'linear',
            }}
          />
        ))}

        {/* Interactive Shapes */}
        {shapes.map((shape, index) => (
          <Shape
            key={index}
            color={shape.color}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 0.5,
              scale: 1,
              x: shape.x + mousePosition.x * 0.02,
              y: shape.y + mousePosition.y * 0.02,
            }}
            whileHover={{
              scale: 1.1,
              opacity: 0.7,
            }}
            whileTap={{ scale: 0.95 }}
            transition={{
              duration: 1,
              delay: shape.delay,
              ease: 'easeOut',
            }}
            style={{
              width: shape.size,
              height: shape.size,
            }}
            tabIndex={0}
            role="button"
            aria-label={`Interactive shape ${index + 1}`}
          />
        ))}

        {/* Interactive Cards */}
        {cards.map((card, index) => (
          <InteractiveCard
            key={index}
            initial={{ opacity: 0, y: 50, z: 0 }}
            animate={{
              opacity: 1,
              x: card.x + mousePosition.x * 0.03,
              y: card.y + mousePosition.y * 0.03,
              rotate: card.rotation,
              z: 50,
            }}
            whileHover={{
              scale: 1.05,
              rotate: 0,
              transition: { duration: 0.3 },
            }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.8 }}
            tabIndex={0}
            role="button"
            aria-label={card.title}
          >
            <motion.h3
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
              style={{
                margin: 0,
                fontSize: '1.2rem',
              }}
            >
              {card.title}
            </motion.h3>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.2 }}
              style={{
                margin: 0,
                fontSize: '0.9rem',
              }}
            >
              {card.description}
            </motion.p>
          </InteractiveCard>
        ))}

        {/* Abstract geometric shapes with scroll triggers */}
        <motion.svg
          width="100%"
          height="100%"
          viewBox="0 0 400 400"
          style={{
            position: 'absolute',
            opacity: 0.5,
            zIndex: -1,
          }}
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 0.5, scale: 1 },
          }}
          transition={{ duration: 1 }}
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{
                  stopColor: theme.palette.primary.light,
                  stopOpacity: 1,
                }}
              />
              <stop
                offset="100%"
                style={{
                  stopColor: theme.palette.primary.main,
                  stopOpacity: 1,
                }}
              />
            </linearGradient>
          </defs>

          <motion.path
            d="M100,100 L300,100 L200,300 Z"
            fill="url(#grad1)"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: isInView ? 1 : 0 }}
            transition={{ duration: 2, ease: 'easeInOut' }}
          />
        </motion.svg>

        {/* Floating elements with scroll triggers */}
        <Box
          component={motion.div}
          sx={{
            position: 'absolute',
            top: '10%',
            left: '50%',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 2,
            padding: 2,
            transform: 'translateX(-50%)',
          }}
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.8, delay: 0.5 }}
        >
          {[...Array(6)].map((_, index) => (
            <motion.div
              key={index}
              style={{
                width: 40,
                height: 40,
                borderRadius: 8,
                backgroundColor: theme.palette.background.paper,
                boxShadow: (theme.shadows as Array<string>)[2], // Cast to Array<string>
              }}
              whileHover={{ scale: 1.1, rotate: 5 }}
              animate={{
                y: [0, -10, 0],
                rotate: [0, 5, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: index * 0.2,
              }}
            />
          ))}
        </Box>
      </motion.div>
    </IllustrationWrapper>
  );
};

export default HeroIllustration;