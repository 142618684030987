// src/pages/index.tsx

import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  Typography,
  Button,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { motion } from 'framer-motion';
import {
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';

// Import existing components
import HeroIllustration from '../components/HeroIllustration';
import Web3HeroIllustration from '../components/Web3HeroIllustration';
import DoctorMaleIllustration from '../components/DoctorMaleIllustration';
import DoctorFemaleIllustration from '../components/DoctorFemaleIllustration';

// Import the new VideoIllustration component
import VideoIllustration from '../components/VideoIllustration';

// Dynamic imports
const Navbar = dynamic(() => import('../components/Navbar'));
const Footer = dynamic(() => import('../components/Footer'));

// Styled components
const HeroSection = styled('section')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(20),
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  color: theme.palette.text.primary,
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

const HeroOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? alpha(theme.palette.background.default, 0.7)
      : alpha(theme.palette.background.default, 0.5),
  zIndex: 1,
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
}));

const TestimonialCard = styled(Card)(({ theme }) => ({
  height: '100%',
  textAlign: 'center',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.paper,
}));

const SocialIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: theme.spacing(1),
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

// New styled component for the video illustration
const VideoWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: theme.breakpoints.up('md') ? 500 : 350,
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[3],
}));

const HomePage: NextPage = () => {
  const { t: _t, i18n } = useTranslation('common');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const router = useRouter();

  const [darkMode, setDarkMode] = useState(false);

  // Determine if the current language is RTL
  const isRTL = i18n.language === 'fa';

  useEffect(() => {
    // Set dark mode based on system preference or previous selection
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setDarkMode(savedMode === 'dark');
    } else {
      setDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('themeMode', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('themeMode', 'light');
    }
  }, [darkMode]);

  return (
    <>
      <Head>
        <title>{_t('home.title') || 'Home'} | Medicup</title>
        <meta
          name="description"
          content="Medicup - A Web3-powered HIS, RIS, LIS healthcare platform offering seamless integration, blockchain security, and zero-trust technology for the future of healthcare."
        />
        <meta property="og:title" content={_t('home.title')} />
        <meta property="og:description" content={_t('home.description')} />
        <meta name="twitter:title" content={_t('home.title')} />
        <meta name="twitter:description" content={_t('home.description')} />
      </Head>

      {/* Navbar */}
      <Navbar lng={_t('language')} />

      {/* Main Content */}
      <main dir={isRTL ? 'rtl' : 'ltr'}>
        {/* Hero Section */}
        <HeroSection>
          {/* Hero Illustration as Background */}
          <Box
            sx={{
              position: 'absolute',
              inset: 0,
              zIndex: 0,
            }}
          >
            <HeroIllustration />
          </Box>
          {/* Overlay for better text visibility */}
          <HeroOverlay />
          <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '60vh' }}
            >
              <Grid item xs={12} md={8}>
                <HeroContent>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <GradientText
                      variant="h2"
                      variantMapping={{ h2: 'h1' }}
                      gutterBottom
                      sx={{
                        color:
                          theme.palette.mode === 'dark'
                            ? theme.palette.primary.light
                            : 'inherit',
                      }}
                    >
                      The Future of Healthcare is Here with Medicup
                    </GradientText>
                    <Typography
                      variant="h6"
                      color={
                        theme.palette.mode === 'dark'
                          ? 'text.primary'
                          : 'textSecondary'
                      }
                      paragraph
                      sx={{ mb: 4 }}
                    >
                      Harness the power of Web3, blockchain, and zero-trust technology to
                      streamline hospital, radiology, and laboratory management. Medicup
                      integrates HIS, RIS, LIS, and more into a secure, innovative platform.
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href="#features"
                        sx={{
                          borderRadius: 2,
                          textTransform: 'none',
                          px: 4,
                        }}
                      >
                        Explore Our Solutions
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        href="#contact"
                        sx={{
                          borderRadius: 2,
                          textTransform: 'none',
                          px: 4,
                        }}
                      >
                        Get Started
                      </Button>
                    </Box>
                  </motion.div>
                </HeroContent>
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        {/* Features Section */}
        <Box
          py={8}
          id="features"
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.primary.light
                          : 'inherit',
                    }}
                  >
                    Comprehensive Healthcare Solutions
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    color={
                      theme.palette.mode === 'dark'
                        ? 'text.primary'
                        : 'textSecondary'
                    }
                  >
                    At Medicup, we provide integrated healthcare systems designed to
                    streamline operations and enhance patient care. Our platform combines
                    Hospital Information Systems (HIS), Radiology Information Systems (RIS),
                    and Laboratory Information Systems (LIS), all secured with
                    blockchain technology and empowered by Web3 innovations.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    color={
                      theme.palette.mode === 'dark'
                        ? 'text.primary'
                        : 'textSecondary'
                    }
                  >
                    Experience seamless data management, improved diagnostics,
                    and efficient workflows. Our commitment to zero-trust security ensures
                    that your data remains protected, fostering trust and reliability in
                    healthcare services.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    href="/about" // Updated href to point to the About page
                    sx={{ textTransform: 'none', borderRadius: 2, px: 4 }}
                  >
                    Learn More About Our Solutions
                  </Button>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                >
                  <VideoWrapper>
                    <video
                      src="/videos/web3-his.mp4"
                      autoPlay
                      loop
                      muted
                      playsInline
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: theme.shape.borderRadius * 2,
                      }}
                    />
                  </VideoWrapper>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Feature Cards Section */}
        <Box
          py={8}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="stretch">
              {/* Feature Cards */}
              {/* Card 1 */}
              <Grid item xs={12} md={4}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  style={{ height: '100%' }}
                >
                  <FeatureCard sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="h5" component="h3" gutterBottom>
                        Hospital Information System (HIS)
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Manage patient data, appointments, and billing with blockchain-secured systems. Medicup’s HIS integrates seamlessly for efficient operations.
                      </Typography>
                    </CardContent>
                  </FeatureCard>
                </motion.div>
              </Grid>
              {/* Card 2 */}
              <Grid item xs={12} md={4}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  style={{ height: '100%' }}
                >
                  <FeatureCard sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="h5" component="h3" gutterBottom>
                        Radiology Information System (RIS)
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Streamline radiology workflows with secure imaging data management. Medicup&apos;s RIS enhances diagnostic accuracy and reporting efficiency.
                      </Typography>
                    </CardContent>
                  </FeatureCard>
                </motion.div>
              </Grid>
              {/* Card 3 */}
              <Grid item xs={12} md={4}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                  style={{ height: '100%' }}
                >
                  <FeatureCard sx={{ height: '100%' }}>
                    <CardContent>
                      <Typography variant="h5" component="h3" gutterBottom>
                        Laboratory Information System (LIS)
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Efficiently manage lab tests and results with secure data protocols. Medicup&apos;s LIS ensures accurate and timely lab operations.
                      </Typography>
                    </CardContent>
                  </FeatureCard>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Blockchain & Web3 Section */}
        <Box
          py={8}
          sx={{
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={5} alignItems="center">
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                >
                  {/* Use Web3HeroIllustration component here */}
                  <Box
                    sx={{
                      position: 'relative',
                      width: '100%',
                      height: matches ? 550 : 400,
                      overflow: 'hidden',
                      borderRadius: 2,
                      boxShadow: theme.shadows[3],
                    }}
                  >
                    <Web3HeroIllustration />
                  </Box>
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1 }}
                >
                  <Typography
                    variant="h4"
                    component="h2"
                    gutterBottom
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.primary.light
                          : 'inherit',
                    }}
                  >
                    Powered by Blockchain & Web3
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    color={
                      theme.palette.mode === 'dark'
                        ? 'text.primary'
                        : 'textSecondary'
                    }
                  >
                    Medicup utilizes blockchain, Web3 technologies, and zero-trust methodologies to ensure unparalleled security and data privacy in healthcare management systems.
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    href="#contact"
                    sx={{ textTransform: 'none', borderRadius: 2, px: 4 }}
                  >
                    Learn More About Web3 Integration
                  </Button>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Testimonials Section */}
        {/* (Remaining sections unchanged) */}
        {/* Testimonials Section */}
        <Box
          py={8}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h4"
              component="h2"
              align="center"
              gutterBottom
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.light
                    : 'inherit',
              }}
            >
              What Our Clients Say
            </Typography>
            <Grid container spacing={4}>
              {/* Testimonial 1 */}
              <Grid item xs={12} md={6}>
                <TestimonialCard>
                  <DoctorMaleIllustration />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.primary.light
                          : 'inherit',
                    }}
                  >
                    Dr. John Smith
                  </Typography>
                  <Typography
                    variant="body2"
                    color={
                      theme.palette.mode === 'dark' ? 'text.primary' : 'textSecondary'
                    }
                    paragraph
                  >
                    &quot;Medicup has revolutionized the way we manage patient data. The integration of blockchain ensures that our records are secure and immutable.&quot;
                  </Typography>
                </TestimonialCard>
              </Grid>
              {/* Testimonial 2 */}
              <Grid item xs={12} md={6}>
                <TestimonialCard>
                  <DoctorFemaleIllustration />
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      color:
                        theme.palette.mode === 'dark'
                          ? theme.palette.primary.light
                          : 'inherit',
                    }}
                  >
                    Dr. Emily Johnson
                  </Typography>
                  <Typography
                    variant="body2"
                    color={
                      theme.palette.mode === 'dark' ? 'text.primary' : 'textSecondary'
                    }
                    paragraph
                  >
                    &quot;The ease of use and the seamless integration of Medicup&apos;s systems have greatly improved our operational efficiency. It&apos;s a game-changer for healthcare.&quot;
                  </Typography>
                </TestimonialCard>
              </Grid>
              {/* Add more testimonials if needed */}
            </Grid>
          </Container>
        </Box>

        {/* Contact Section */}
        <Box
          py={8}
          sx={{ backgroundColor: theme.palette.grey[200] }}
          id="contact"
        >
          <Container maxWidth="sm">
            <Typography
              variant="h4"
              component="h2"
              align="center"
              gutterBottom
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.light
                    : 'inherit',
              }}
            >
              Get in Touch
            </Typography>
            <Typography
              variant="body1"
              paragraph
              align="center"
              color={
                theme.palette.mode === 'dark' ? 'text.primary' : 'textSecondary'
              }
            >
              Interested in learning more about how Medicup can transform your
              healthcare organization? Reach out to us, and we’ll be happy to
              assist.
            </Typography>
            <Box textAlign="center" mt={4}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="mailto:info@medicup.com"
                sx={{ textTransform: 'none', borderRadius: 2, px: 4 }}
              >
                Contact Us
              </Button>
            </Box>
          </Container>
        </Box>

        {/* Social Media Section */}
        <Box
          py={5}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h5"
              component="h2"
              align="center"
              gutterBottom
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? theme.palette.primary.light
                    : 'inherit',
              }}
            >
              {_t('Follow Us on Social Media')}
            </Typography>
            <Box display="flex" justifyContent="center">
              <a
                href="https://www.facebook.com/medicup.431041" // Replace with your Facebook page URL
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <SocialIconButton>
                  <FaFacebook size={32} />
                </SocialIconButton>
              </a>
              <a
                href="https://x.com/medicup292830" // Replace with your Instagram profile URL
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <SocialIconButton>
                  <FaTwitter size={32} />
                </SocialIconButton>
              </a>
              <a
                href="https://www.instagram.com/medicup212" // Replace with your Instagram profile URL
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <SocialIconButton>
                  <FaInstagram size={32} />
                </SocialIconButton>
              </a>
              <a
                href="https://wa.me/your-whatsapp-number" // Replace with your WhatsApp number in international format
                target="_blank"
                rel="noopener noreferrer"
                aria-label="WhatsApp"
              >
                <SocialIconButton>
                  <FaWhatsapp size={32} />
                </SocialIconButton>
              </a>
              <a
                href="https://t.me/medicupdk" // Replace with your Telegram username
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Telegram"
              >
                <SocialIconButton>
                  <FaTelegramPlane size={32} />
                </SocialIconButton>
              </a>
            </Box>
          </Container>
        </Box>
      </main>

      {/* Footer */}
      <Footer />
    </>
  );
};

// Static props for translations
export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', [
        'common',
        'navbar',
        'footer',
      ])),
    },
  };
};

export default HomePage;