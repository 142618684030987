// components/Web3HeroIllustration.tsx

import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles'; // Adjusted import
import {
  motion,
  useInView,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import { styled } from '@mui/material/styles'; // Adjusted import

const IllustrationWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  minHeight: 700,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  perspective: '1000px',
  background:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg, #0A0B0E 0%, #1F1F3A 100%)'
      : 'linear-gradient(180deg, #E3F2FD 0%, #BBDEFB 100%)',
  [theme.breakpoints.down('md')]: {
    minHeight: 500,
  },
}));

const BlockchainNode = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  backdropFilter: 'blur(8px)',
  borderRadius: 12,
  padding: theme.spacing(2),
  border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
  boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.2)}`,
  cursor: 'pointer',
  width: 120,
  height: 120,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const ConnectionLine = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  height: 2,
  background: `linear-gradient(90deg, ${alpha(
    theme.palette.primary.light,
    0.5
  )}, ${alpha(theme.palette.primary.main, 0.8)})`,
  transformOrigin: 'left center',
}));

const CryptoParticle = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  width: 8,
  height: 8,
  borderRadius: '50%',
  background: theme.palette.primary.main,
  boxShadow: `0 0 10px ${alpha(theme.palette.primary.main, 0.5)}`,
}));

// Define the HexagonGrid component
const HexagonGrid = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  width: '200%',
  height: '200%',
  background: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30,0 L60,15 L60,45 L30,60 L0,45 L0,15 Z' fill='none' stroke='${alpha(
    theme.palette.primary.main,
    0.1
  )}' /%3E%3C/svg%3E")`,
  backgroundSize: '90px 90px',
  opacity: 0.2,
  transform: 'translate(-50%, -50%)',
}));

const Web3Card = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: alpha(theme.palette.background.paper, 0.85),
  backdropFilter: 'blur(12px)',
  borderRadius: 20,
  padding: theme.spacing(3),
  border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
  boxShadow: theme.shadows![4], // Added non-null assertion operator
  color: theme.palette.text.primary,
  width: 200,
  cursor: 'pointer',
  textAlign: 'center',
}));

interface NodeProps {
  id: number;
  x: number;
  y: number;
  label: string;
}

interface CardProps {
  title: string;
  value: string;
  x: number;
  y: number;
}

const Web3HeroIllustration: React.FC = () => {
  const theme = useTheme();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(wrapperRef, { once: false });
  const controls = useAnimation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [viewportSize, setViewportSize] = useState({ width: 0, height: 0 });
  const [particles, setParticles] = useState<
    { x: number; y: number; opacity: number }[]
  >([]);

  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);
  const rotateX = useTransform(mouseY, [-300, 300], [15, -15]);
  const rotateY = useTransform(mouseX, [-300, 300], [-15, 15]);

  const blockchainNodes: NodeProps[] = useMemo(
    () => [
      { id: 1, x: -200, y: -100, label: 'Smart Contract' },
      { id: 2, x: 0, y: -150, label: 'Wallet' },
      { id: 3, x: 200, y: -100, label: 'dApp' },
      { id: 4, x: -150, y: 100, label: 'NFT' },
      { id: 5, x: 150, y: 100, label: 'DeFi' },
    ],
    []
  );

  const web3Cards: CardProps[] = useMemo(
    () => [
      {
        title: 'ETH Balance',
        value: '128.45 ETH',
        x: -250,
        y: 0,
      },
      {
        title: 'Gas Price',
        value: '32 GWEI',
        x: 250,
        y: 0,
      },
    ],
    []
  );

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  // Throttled mouse move handler
  useEffect(() => {
    let animationFrameId: number;

    const handleMouseMove = (e: MouseEvent) => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left - rect.width / 2;
        const y = e.clientY - rect.top - rect.height / 2;
        animationFrameId = requestAnimationFrame(() => {
          setMousePosition({ x, y });
          mouseX.set(x);
          mouseY.set(y);
        });
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [mouseX, mouseY]);

  // Get viewport size and generate particles
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setViewportSize({ width: window.innerWidth, height: window.innerHeight });
      };

      handleResize(); // Set initial size

      window.addEventListener('resize', handleResize);

      // Generate particles after viewport size is set
      const particleCount = window.innerWidth > 768 ? 25 : 15;
      const newParticles = Array.from({ length: particleCount }, () => ({
        x: Math.random() * window.innerWidth - window.innerWidth / 2,
        y: Math.random() * window.innerHeight - window.innerHeight / 2,
        opacity: Math.random() * 0.5 + 0.2,
      }));
      setParticles(newParticles);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <IllustrationWrapper ref={wrapperRef}>
      {/* Hexagon Grid Background */}
      <HexagonGrid
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.3 }}
        transition={{ duration: 1 }}
      />

      <motion.div
        style={{
          width: '100%',
          height: '100%',
          rotateX,
          rotateY,
          transformStyle: 'preserve-3d',
        }}
      >
        {/* Blockchain Nodes */}
        {blockchainNodes.map((node, index) => (
          <BlockchainNode
            key={node.id}
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: 1,
              scale: 1,
              x: node.x + mousePosition.x * 0.02,
              y: node.y + mousePosition.y * 0.02,
            }}
            whileHover={{
              scale: 1.1,
              boxShadow: `0 0 30px ${alpha(theme.palette.primary.main, 0.4)}`,
            }}
            transition={{
              duration: 0.8,
              delay: index * 0.2,
              ease: 'easeOut',
            }}
            tabIndex={0}
            aria-label={node.label}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {node.label}
            </motion.div>
          </BlockchainNode>
        ))}

        {/* Connection Lines */}
        {blockchainNodes.map((from, i) =>
          blockchainNodes.slice(i + 1).map((to) => {
            const angle = (Math.atan2(to.y - from.y, to.x - from.x) * 180) / Math.PI;
            const distance = Math.sqrt(
              Math.pow(to.x - from.x, 2) + Math.pow(to.y - from.y, 2)
            );
            return (
              <ConnectionLine
                key={`${from.id}-${to.id}`}
                style={{
                  width: distance,
                  left: from.x + 60 + mousePosition.x * 0.02,
                  top: from.y + 60 + mousePosition.y * 0.02,
                  rotate: `${angle}deg`,
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                transition={{ delay: i * 0.2, duration: 1 }}
              />
            );
          })
        )}

        {/* Web3 Cards */}
        {web3Cards.map((card, index) => (
          <Web3Card
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              x: card.x + mousePosition.x * 0.03,
              y: card.y + mousePosition.y * 0.03,
            }}
            whileHover={{
              scale: 1.05,
              boxShadow: theme.shadows![6],
            }}
            transition={{ delay: index * 0.2 }}
            tabIndex={0}
            aria-label={card.title}
          >
            <motion.h3
              style={{
                margin: 0,
                marginBottom: '8px',
                fontSize: '16px',
                opacity: 0.7,
              }}
            >
              {card.title}
            </motion.h3>
            <motion.div style={{ fontSize: '24px', fontWeight: 'bold' }}>
              {card.value}
            </motion.div>
          </Web3Card>
        ))}

        {/* Floating Crypto Particles */}
        {particles.map((particle, index) => (
          <CryptoParticle
            key={index}
            style={{
              x: particle.x,
              y: particle.y,
              opacity: particle.opacity,
            }}
            animate={{
              y: [particle.y, particle.y - 200],
              opacity: [particle.opacity, 0],
              scale: [1, 1.5],
            }}
            transition={{
              duration: 5,
              repeat: Infinity,
              delay: index * 0.2,
              ease: 'linear',
            }}
          />
        ))}
      </motion.div>
    </IllustrationWrapper>
  );
};

export default Web3HeroIllustration;